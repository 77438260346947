import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line

//Data
import dataEskerSolutionsPages from "data/dataEskerSolutionsPages";

import Header, { NavLink, NavLinks, PrimaryLink as PrimaryLinkBase, LogoLink, NavToggle, DesktopNavLinks } from "../../components/headers/light";

//Componentes
import Home from 'components/hero/PagesComponent'
import ThreeColImg from "components/cards/ThreeColImgCenter";
import Footer from "components/footers/RequestFooter";


const StyledHeader = styled(Header)`
  ${tw`max-w-none w-full bg-red-800 fixed h-24 w-screen z-20 p-2 shadow-xl text-gray-400`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw` hover:border-gray-300 hover:text-white`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;

const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 `}`;

export default () => {

  return (
    <AnimationRevealPage disabled>
      <Container>
        <StyledHeader />
        <Home
          heading = { dataEskerSolutionsPages.orderToCash.home.heading }
          subheading = { dataEskerSolutionsPages.orderToCash.home.subheading }
          imgSrc = { dataEskerSolutionsPages.orderToCash.home.imgSrc }
        />

        <ThreeColImg
          heading = {
            <>
              { dataEskerSolutionsPages.orderToCash.infoSection.heading }
              <span tw="text-red-700">
                { dataEskerSolutionsPages.orderToCash.infoSection.headingSpan }
              </span> 
              { dataEskerSolutionsPages.orderToCash.infoSection.headingContinuacion } 
            </>
          }
          description = { dataEskerSolutionsPages.orderToCash.infoSection.description }
          cardsLeft = { dataEskerSolutionsPages.orderToCash.cards.cardsLeft }
          imgSrc = { dataEskerSolutionsPages.orderToCash.infoSection.imgSrc }
          cardsRight = { dataEskerSolutionsPages.orderToCash.cards.cardsRight }
        />
        <Footer />
      </Container>
    </AnimationRevealPage>
  );
};
