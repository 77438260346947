import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line

//Componentes
import Home from 'components/hero/PagesComponent'
import Info from "components/cards/InfoComponent";
import Footer from "components/footers/RequestFooter";

import Header, { NavLink, NavLinks, PrimaryLink as PrimaryLinkBase, LogoLink, NavToggle, DesktopNavLinks } from "../../components/headers/light";

import dataRealCaseStudy from "data/dataRealCaseStudy";


const StyledHeader = styled(Header)`
  ${tw`max-w-none w-full bg-red-800 fixed h-24 w-screen z-20 p-2 shadow-xl text-gray-400`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw` hover:border-gray-300 hover:text-white`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;

const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 `}`;


export default () => {

  return (
    <AnimationRevealPage disabled>
      <Container>
        <StyledHeader />
        <Home
          heading = { dataRealCaseStudy.aliaxis.heading }
          subheading = { dataRealCaseStudy.aliaxis.subheading }
          imgSrc={ dataRealCaseStudy.aliaxis.headerImg }
        />
        <div tw="py-12 md:py-12">
          <Info 
            title = { dataRealCaseStudy.aliaxis.info.title }
            heading = { dataRealCaseStudy.aliaxis.info.heading[0] }
            subheading = { dataRealCaseStudy.aliaxis.info.subheading[0] }
            description = { dataRealCaseStudy.aliaxis.info.description[0] }
          />
          <Info 
            heading = { dataRealCaseStudy.aliaxis.info.heading[1] }
            subheading = { dataRealCaseStudy.aliaxis.info.subheading[1] }
            description = { dataRealCaseStudy.aliaxis.info.description[1] }
          />
          <Info 
            heading = { dataRealCaseStudy.aliaxis.info.heading[2] }
            subheading = { dataRealCaseStudy.aliaxis.info.subheading[2] }
            description = { dataRealCaseStudy.aliaxis.info.description[2] }
          />
        </div>
        <Footer />
      </Container>
    </AnimationRevealPage>
  );
};
