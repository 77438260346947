import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./carousel.css"

import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";


const Container = tw.div`relative -mt-6`;
const Content = tw.div`flex flex-col max-w-screen-xl mx-auto pt-20 pb-4`;
//py-20 lg:py-20 
const Heading = tw(SectionHeading)`w-full max-w-screen-md text-center`;
const HeadingCarousel = tw(SectionHeading)`w-full max-w-screen-md leading-tight mx-auto text-3xl sm:text-3xl text-center`;
const Subheading = tw(SubheadingBase)`w-full font-bold text-2xl text-center mb-4 uppercase pb-20`;
const Description = tw(SectionDescription)`w-full text-center max-w-screen-lg mt-0`;

// const Heading = tw(SectionHeading)`w-full max-w-screen-sm text-center`;
// const Description = tw(SectionDescription)`w-full text-center max-w-screen-lg mt-0`;
// const Subheading = tw(SubheadingBase)`w-full font-bold text-2xl text-center mb-4`;

//Para hacer el carousel responsive tengo que ver como cambiar la propiedad del display de cuantas imagenes se muestran en pantalla y a la vez ver como hacerlo mobile responsive


export default ({
    heading = "",
    headingCarousel = "",
    subheading = "", 
    description = "",
    imgs = null,
    settings = {
        infinite: true,
        dots: false,
        slidesToShow: 5,
        slidesToScroll: 1,
        lazyLoad: true,
        autoplay: true,
        autoplaySpeed: 1000,
    }
}) => {

    return (
        <Container>
            <Content>
                <div tw="flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center">
                    {/* <Heading>{heading}</Heading> */}
                    {heading && <Heading>{heading}</Heading>}
                    {headingCarousel && <HeadingCarousel>{headingCarousel}</HeadingCarousel>}
                    {subheading && <Subheading>{subheading}</Subheading>}
                    {description && <Description>{description}</Description>}
                </div>
                <div className="imgslider">
                    <Slider {...settings}>
                        {imgs.map((item) => (
                            <div key={item.id}>
                                <img className="img" src={item.src}  alt={item.alt} />
                            </div>
                        ))}
                    </Slider>
                </div>
            </Content>
        </Container>
    );
};
