import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import ResponsiveVideo from '../../helpers/ResponsiveVideoEmbed'

const Container = tw.div`relative -mt-6`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center mx-auto py-20 md:py-24`}
`;
const Heading = tw(SectionHeading)`w-full max-w-screen-sm`;
const Subheading = tw(SubheadingBase)`w-full font-bold text-2xl text-center mb-4 uppercase`;
const Description = tw(SectionDescription)`w-full text-center max-w-screen-lg mt-0`;
// const Heading = tw(SectionHeading)`w-full max-w-screen-md text-center`;
// const HeadingCarousel = tw(SectionHeading)`w-full max-w-screen-md leading-tight p-2 mx-auto uppercase text-3xl sm:text-3xl text-center`;
// const Subheading = tw(SubheadingBase)`w-full font-bold text-2xl text-center mb-4 uppercase`;

const VerticalSpacer = tw.div`mt-10 w-full`

const Column = styled.div`
  ${tw`md:w-1/3 lg:w-1/2 flex flex-col justify-center`}
`;


const StyledResponsiveVideoEmbed = styled(ResponsiveVideo)`
  ${tw`rounded`}
  iframe {
    ${tw``}
  }
`;

export default ({
    heading = "",
    subheading = "", 
    description = "",
    urlVideo = null,
}) => {


  return (
    <Container>
      <ThreeColumnContainer>
        <Heading>{heading}</Heading>
        {subheading && <Subheading>{subheading}</Subheading>}
        {description && <Description>{description}</Description>}
        <VerticalSpacer />
        <Column>
        <StyledResponsiveVideoEmbed
            url={urlVideo}
        />
        </Column>
      </ThreeColumnContainer>
    </Container>
  );
};
