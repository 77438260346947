import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line

//Data
import dataEskerSolutionsPages from "../data/dataEskerSolutionsPages";

import Header, { NavLink, NavLinks, PrimaryLink as PrimaryLinkBase, LogoLink, NavToggle, DesktopNavLinks } from "../components/headers/light";

//Componentes
import Home from 'components/hero/HeroWithButtons'
import VideoCenter from "components/cards/OneColVideoCenter";
import Footer from "components/footers/RequestFooter";

const StyledHeader = styled(Header)`
  ${tw`max-w-none w-full bg-red-800 fixed h-24 w-screen z-20 p-2 shadow-xl text-gray-400`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw` hover:border-gray-300 hover:text-white`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;

const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 `}`;

export default () => {

  return (
    <AnimationRevealPage disabled>
      <Container>
        <StyledHeader/>
        <Home
          heading = { dataEskerSolutionsPages.eskerSolutions.home.heading }
          subheading = { dataEskerSolutionsPages.eskerSolutions.home.subheading }
          imgSrc = { dataEskerSolutionsPages.eskerSolutions.home.imgSrc }
          buttonL = { dataEskerSolutionsPages.eskerSolutions.home.buttonL }
          buttonR = { dataEskerSolutionsPages.eskerSolutions.home.buttonR }
        />
        <VideoCenter
          heading = { dataEskerSolutionsPages.eskerSolutions.videoSection.heading }
          span = ""
          subheading = { dataEskerSolutionsPages.eskerSolutions.videoSection.subheading }
          description = { dataEskerSolutionsPages.eskerSolutions.videoSection.description }
          urlVideo = { dataEskerSolutionsPages.eskerSolutions.videoSection.urlVideo }
        />
        <Footer />
      </Container>
    </AnimationRevealPage>
  );
};
