import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line

import dataProfessionalServices from '../../data/dataProfessionalServicesPages';

import Home from 'components/hero/PagesComponent'
import Addon from "components/features/TwoColSingleFeatureWithStats2.js";
import Footer from "components/footers/RequestFooter";

import Header, { NavLink, NavLinks, PrimaryLink as PrimaryLinkBase, LogoLink, NavToggle, DesktopNavLinks } from "../../components/headers/light";

//Componente con navbar
const PrimaryLink = tw(PrimaryLinkBase)`rounded-full`

const StyledHeader = styled(Header)`
  ${tw`max-w-none w-full bg-red-800 fixed h-24 w-screen z-20 p-2 shadow-xl text-gray-400`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw` hover:border-gray-300 hover:text-white`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;

const Divider = tw.div`my-4 border-b-2 border-gray-400 w-full`;

const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 `}`;



export default () => {

  return (
    <AnimationRevealPage disabled>
      <Container>
        <StyledHeader />
        <Home
            heading = { dataProfessionalServices.add_on.headerHeading }
            imgSrc = { dataProfessionalServices.add_on.headerImg }
        />
        <Addon
            heading={    
              <>
                <div tw="text-4xl">
                  { dataProfessionalServices.add_on.info.factura_electronica.heading }
                  <span tw="text-red-700">
                    
                  </span>
                </div>
              </>
            }
            description = { dataProfessionalServices.add_on.info.factura_electronica.description }
            descriptionDos = { dataProfessionalServices.add_on.info.factura_electronica.descriptionDos }
            descriptionTres = ""
            descriptionCuatro = ""
            imageLogo={ dataProfessionalServices.add_on.info.factura_electronica.imageLogo }
            imageSrc = { dataProfessionalServices.add_on.info.factura_electronica.imageSrc }
        />
        <Divider />
        <Addon
            heading={    
              <>
                <div tw="text-4xl">
                  { dataProfessionalServices.add_on.info.cot.heading }
                  <span tw="text-red-700">
                    
                  </span>
                </div>
              </>
            }
            description = { dataProfessionalServices.add_on.info.cot.description }
            descriptionDos = { dataProfessionalServices.add_on.info.cot.descriptionDos }
            descriptionTres = { dataProfessionalServices.add_on.info.cot.descriptionTres }
            descriptionCuatro = ""
            textOnLeft = {true}
            imageLogo = { dataProfessionalServices.add_on.info.cot.imageLogo }
            imageSrc = { dataProfessionalServices.add_on.info.cot.imageSrc }
            listTitle = { dataProfessionalServices.add_on.info.cot.listTitle }
            list = { dataProfessionalServices.add_on.info.cot.list }
            
        />
        <Divider />
        <Addon
            heading={    
              <>
                <div tw="text-4xl">
                  { dataProfessionalServices.add_on.info.factura_apocrifa.heading }
                  <span tw="text-red-700">
                    
                  </span>
                </div>
              </>
            }
            description = { dataProfessionalServices.add_on.info.factura_apocrifa.description }
            descriptionDos = { dataProfessionalServices.add_on.info.factura_apocrifa.descriptionDos }
            descriptionTres = { dataProfessionalServices.add_on.info.factura_apocrifa.descriptionTres }
            descriptionCuatro = { dataProfessionalServices.add_on.info.factura_apocrifa.descriptionCuatro }
            // imageLogo = { dataProfessionalServices.add_on.info.factura_apocrifa.imageLogo }
            imageSrc = { dataProfessionalServices.add_on.info.factura_apocrifa.imageSrc }
        />
        
        <Footer />
      </Container>
    </AnimationRevealPage>
  );
};
