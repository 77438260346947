import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line


import img from '../images/backgrounds/professionalservices.jpg'

import Header, { NavLink, NavLinks, PrimaryLink as PrimaryLinkBase, LogoLink, NavToggle, DesktopNavLinks } from "../components/headers/light";

import Home from 'components/hero/PagesComponent'
import Footer from "components/footers/RequestFooter";




//Componente con navbar
const PrimaryLink = tw(PrimaryLinkBase)`rounded-full`

const StyledHeader = styled(Header)`
  ${tw`max-w-none w-full bg-red-800 fixed h-24 w-screen z-20 p-2 shadow-xl text-gray-400`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw` hover:border-gray-300 hover:text-white`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;

const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 `}`;

const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {

  return (
    <AnimationRevealPage disabled>
      <Container>
        <StyledHeader/>
        <Home 
          heading = "Professional Services"
          subheading = ""
          imgSrc={img}
        />
        {/* <MainFeature1
          subheading={<Subheading>About Treact</Subheading>}
          heading="We are a modern design agency."
          buttonRounded={false}
          primaryButtonText="See Portfolio"
          imageSrc="https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80"
        /> */}
        <Footer />
      </Container>
    </AnimationRevealPage>
  );
};
