import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import IRAM from "../../images/request/request_iram.png";
import REQUEST from "../../images/request/logoRequestBlanco-Transparente.png";
import EskerCP from '../../images/logos_partners/Logo_Esker_Certified_Partner-C_blanco.png'


const Container = tw.div`relative bg-gray-800 text-gray-700 -mb-8 -mx-8 py-16 lg:pt-4 lg:pb-10`;
const Content = tw.div`max-w-screen-xl mx-auto relative z-10`;


const LinkList = tw.ul`flex mt-4 text-sm font-medium mx-auto`;
const LinkListItem = tw.li`py-4 mx-auto`;
const Link = tw.a`border-b-2 border-transparent text-gray-600  pb-1 transition duration-300`;

const Divider = tw.div`my-4 border-b-2 border-gray-300 w-full`;

const ThreeColRow = tw.div`flex flex-col md:flex-row items-center justify-between px-24`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start `;
const LogoImg = tw.a`w-64`;
const IramImg = tw.img`w-40 m-8`;


const SocialLinksContainer = tw.div`md:mt-0 flex`;


export default () => {
  return (
    <Container>
      <Content>
        
      <ThreeColRow>
        <LogoContainer>
          <IramImg src={IRAM}></IramImg>
        </LogoContainer>
        <SocialLinksContainer>
          <LogoImg href="/requestsa">
            <img src={REQUEST} alt="logo"/>
          </LogoImg>
        </SocialLinksContainer>
        <SocialLinksContainer>
          <LogoImg tw="w-40" href="https://www.esker.com/">
            <img src={EskerCP} alt="logo"/>
          </LogoImg>
        </SocialLinksContainer>
      </ThreeColRow>
        <Divider />
            <LinkList>
              <LinkListItem>
                <Link> &copy; Request Information Technology. All Rights Reserved.</Link>
              </LinkListItem>
              <LinkListItem>
                <Link>info@request.com.ar</Link>
              </LinkListItem>
              <LinkListItem>
                <Link>Tel: +54 11 4545 8333</Link>
              </LinkListItem>
              <LinkListItem>
                <Link> Machain 4289 Piso 1 Ciudad Autonoma de Buenos Aires - Argentina</Link>
              </LinkListItem>
            </LinkList>
      </Content>
    </Container>
  );
};
      
