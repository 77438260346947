//imgs about
import flecha from '../images/imgs/DesarrolloLllaveMano.png'


//imgs professional services
import SAP from "../images/logos_partners/sap_reducido.png"
import ESKER from "../images/logos_partners/esker_reducido.jpg"
import FSDEV from "../images/logos_partners/fsdev_reducido.jpg"

//imgs real case study
import AliaxisCase from "../images/real_case_study/Aliaxis.jpg"
import DomingoAlonsoCase from "../images/real_case_study/DomingoAlonso.jpg"
import EmasalCase from "../images/real_case_study/Emasal.jpg"
import SiemensHealthineersCase from "../images/real_case_study/SiemensHealthineers.jpg"

//imgs carousel
//ORDEN de los PRIMEROS
// YPF
// Ingredion
// Aliaxis
// Havas
// Multiradio
// Ecogas
import Agrofina from '../images/logos_customers/Agrofina.jpg'
import Aliaxis from '../images/logos_customers/Aliaxis.jpg'
import AustinPowder from '../images/logos_customers/AustinPowder.jpg'
import AveryDennison from '../images/logos_customers/AveryDennison.jpg'

import DelMonte from '../images/logos_customers/DelMonte.jpg'
import DigitalHouse from '../images/logos_customers/DigitalHouse.jpg'
import Disney from '../images/logos_customers/Disney.jpg'
import DomingoAlonso from '../images/logos_customers/DomingoAlonso.jpg'
import Ecogas from '../images/logos_customers/Ecogas.jpg'
import Emasal from '../images/logos_customers/Emasal.jpg'
import Getty from '../images/logos_customers/Getty.jpg'
import Havas from '../images/logos_customers/Havas.jpg'
import Honda from '../images/logos_customers/Honda.jpg'
import Ingredion from '../images/logos_customers/Ingredion.jpg'
import KimberlyClark from '../images/logos_customers/KimberlyClark.jpg'
import LaNacion from '../images/logos_customers/LaNacion.jpg'
import Multiradio from '../images/logos_customers/Multiradio.jpg'
import Pirelli from '../images/logos_customers/Pirelli.jpg'
import RedSurcos from '../images/logos_customers/RedSurcos.jpg'
import Rheem from '../images/logos_customers/Rheem.jpg'
import Roca from '../images/logos_customers/Roca.jpg'
import SCJohnson from '../images/logos_customers/SCJohnson.jpg'
import Seidor from '../images/logos_customers/Seidor.jpg'
import Siemens from '../images/logos_customers/Siemens.jpg'
import Sinergium from '../images/logos_customers/Sinergium.jpg'
import TyCSports from '../images/logos_customers/TyCSports.jpg'
import WarnerMedia from '../images/logos_customers/WarnerMedia.jpg'
import YPF from '../images/logos_customers/YPF.jpg'

//img footer 
import logo from "../images/request/logoRequestBlanco-Transparente.png";

const rutaServidor = "/requestsa"
export var dataMainPage =  {

    header: {
        heading: "REQUEST",
        headingSpan: "Information Technology",
        subheading: "Best Practices for Outstanding Results"
    },

    about: {
        heading: "We have been doing this since",
        headingSpan: " 2000.",
        description: "We are an organization dedicated to providing computer consulting services for business solutions. We focus on companies that understand the strategic importance of their information systems.",
        descriptionDos: "All our professionals are supported by a constant training process, with a special interest in service quality, which results in reliable and committed consulting support that constitutes a safe protection of your investment.",
        descriptionTres: "The services we provide, the work methodology and all our experience are the tools we put at your disposal to achieve the proposed objectives.",
        // Our action covers the tasks that, together with the client, we agree are convenient. That is why, depending on the complexity of the project and the quantity and quality of the client's staff, our participation can range from just advice to total project management.",
        // descriptionCuatro: "Focusing on solutions from a purely economic perspective can prevent the correct analysis of requirements and priorities, which is why our work begins with an analysis of the needs that help us understand what the user organization is like and what the problems to be solved are; Based on this understanding, we manage all our potential to produce efficient solutions to real problems and/or needs, seeking an adequate cost-benefit balance for the investment to be made.",
        imageSrc: flecha
    },
    professionalServices: {
        heading: "Our Professional",
        headingSpan: " Services",
        cards: [
            {
                imageSrc: SAP,
                title: "SAP Developing Services",
                description: "Integrate non-SAP processes with S/4 HANA and other SAP modules via custom devs, third-party apps, web interfaces and build strong enterprise architecture." 
            },
            {
                imageSrc: FSDEV, 
                title: "FULL STACK Development",
                description: "We offer Full Stack development services including frontend, backend and database management."
            },
            { 
                imageSrc: ESKER,
                title: "PROCURE-TO-PAY",
                description: "Transforming the Way Businesses Purchase, Book and Pay."
            },
            { 
                imageSrc: ESKER, 
                title: "ORDER-TO-CASH",
                description: "Uniting the People and Processes That Impact Customer Experience"
            },   
        ]
    },
    customers: {
        heading: "We've done some ",
        headingSpan: "amazing projects.",
        cardLinkText: "Read Case Study",
        textOnLeft: false,
        cards: [
            {
              imageSrc: SiemensHealthineersCase,
              company: "Siemens Healthineers",
              type: "ORDER MANAGEMENT",
              title: "Siemens Healthineers Enhances Its ORDER MANAGEMENT Process with Esker’s AI-Driven Solution",
              buttonLink: `${rutaServidor + '/siemens-healthineers-case'}`,
            },
            {
              imageSrc: AliaxisCase,
              company: "Aliaxis",
              type: "ORDER MANAGEMENT",
              title: "Optimizing ORDER MANAGEMENT for the improvement of customer service",
              buttonLink: `${rutaServidor + '/aliaxis-case'}`,
            },
            // {
            //   imageSrc: EmasalCase,
            //   company: "Emasal",
            //   type: "ACCOUNTS PAYABLE",
            //   title: "Achieving AP efficiency with an End-To-End, AI-Based automation solution",
            //   //"ACHIEVING AP EFFICIENCY WITH AN END-TO-END, AI-BASED AUTOMATION SOLUTION.",
            //   buttonLink: `${rutaServidor + '/emasal-case'}`,
            // },
            {
              imageSrc: DomingoAlonsoCase,
              company: "Domingo Alonso",
              type: "ACCOUNTS PAYABLE",
              title: "Achieving AP efficiency with an End-To-End, AI-Based automation solution",
              buttonLink: `${rutaServidor + '/domingo-alonso-case'}`,
            },
            
        ]
    },
    carousel: {
        heading: "Our",
        headingSpan: " Customers",
        images: [
            {
                id: 1,
                src: YPF,
                alt: "Image 1"
            },
            {
                id: 2,
                src: Ingredion,
                alt: "Image 2"
            },
            {
                id: 3,
                src: Aliaxis,
                alt: "Image 3 "
            },
            {
                id: 4,
                src: Havas,
                alt: "Image 4"
            },
            {
                id: 5,
                src: Multiradio,
                alt: "Image 5"
            },
            {
                id: 6,
                src: Ecogas,
                alt: "Image 1"
            },
            {
                id: 7,
                src: Disney,
                alt: "Image 2"
            },
            {
                id: 8,
                src: Rheem,
                alt: "Image 3 "
            },
            {
                id: 9,
                src: Emasal,
                alt: "Image 4"
            },
            {
                id: 10,
                src: Getty,
                alt: "Image 5"
            },
            {
                id: 11,
                src: Agrofina,
                alt: "Image 5"
            },
            {
                id: 12,
                src: DigitalHouse,
                alt: "Image 4"
            },
            {
                id: 13,
                src: DelMonte,
                alt: "Image 5"
            },
            {
                id: 14,
                src: DomingoAlonso,
                alt: "Image 5"
            },
            {
                id: 14,
                src: Honda,
                alt: "Image 5"
            },
            {
                id: 14,
                src: KimberlyClark,
                alt: "Image 5"
            },
            {
                id: 14,
                src: LaNacion,
                alt: "Image 5"
            },
            {
                id: 14,
                src: Pirelli,
                alt: "Image 5"
            },
            {
                id: 14,
                src: RedSurcos,
                alt: "Image 5"
            },
            {
                id: 14,
                src: Roca,
                alt: "Image 5"
            },
            {
                id: 14,
                src: SCJohnson,
                alt: "Image 5"
            },
            {
                id: 14,
                src: Seidor,
                alt: "Image 5"
            },
            {
                id: 14,
                src: Siemens,
                alt: "Image 5"
            },
            {
                id: 14,
                src: Sinergium,
                alt: "Image 5"
            },
            {
                id: 14,
                src: TyCSports,
                alt: "Image 5"
            },
            {
                id: 14,
                src: WarnerMedia,
                alt: "Image 5"
            },
        ]
    },
    contactUs: {
        heading: "We’re ",
        headingSpan: " Hiring.",
        description: "REQUEST S.A. has an excellent foundation and is determined to grow aggressively. Our team has been working together for years, with a proven track record for producing effective and timely solutions for technically demanding, high pressure projects.",
        descriptionDos: "We are looking for smart, talented and committed people able to work in an innovative and dynamic environment.",
        submitBUttonText: "Contact Us",
        formAction: "#",
        formMethod: "get",
        textOnLeft: false
    },
    footer: {
        copyrightText: " Copyright 2020, Request SA Inc. All Rights Reserved.",
        logo: logo

    }
}

export default dataMainPage;
