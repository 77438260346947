import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
//PAGINA DE ENTRADA

import img from '../../images/backgrounds/procure-to-pay.jpg'


const Container = styled.div`
  ${tw`relative bg-center bg-cover h-1/2 content-center border-b-4 border-gray-200`}
  
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-red-800 bg-gray-700 opacity-25 `;


const HeroContainer = tw.div`relative sm:pt-4 py-20  h-full flex flex-col z-10`;

const Content = tw.div`sm:pt-24 flex flex-1 flex-col justify-center items-center content-center`;


const Heading = styled.h1`
  ${tw`text-5xl text-center sm:text-4xl lg:text-4xl xl:text-5xl font-semibold text-white leading-snug z-20 tracking-tighter`}
  span {
    ${tw`inline-block mt-2`}
  }
`;
const Subheading = styled.h1`
${tw`text-2xl text-center sm:text-2xl lg:text-2xl xl:text-3xl text-white`}`
  ;

const PrimaryButton = tw(PrimaryButtonBase)`text-lg inline-block bg-red-700 hocus:bg-red-500 rounded-full mx-8`;

export default ({
  heading = "",
  subheading = "",
  imgSrc = img,
  buttonL = null,
  buttonR = null
}) => {

  return (
    <Container style={{backgroundImage: `url(${imgSrc})`}}>
      <OpacityOverlay />
      <HeroContainer>
        <Content>
          <div tw="absolute z-20">
            <Heading>{heading}</Heading>
            <Subheading >{subheading}</Subheading>
            <div tw="text-center mt-10 mb-4">
              <PrimaryButton><a href="/requestsa/procure-to-pay">{buttonL}</a></PrimaryButton>
              <PrimaryButton><a href="/requestsa/order-to-cash">{buttonR}</a></PrimaryButton>
            </div>
          </div>
          <div tw="bg-gray-900 max-w-6xl w-full m-16 px-32 py-32 opacity-75 z-10"></div>
        </Content>
      </HeroContainer>
    </Container>
  );
};
