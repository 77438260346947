import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";
import ResponsiveVideo from '../../helpers/ResponsiveVideoEmbed'

import SupportIconImage from "../../images/support-icon.svg";
import ShieldIconImage from "../../images/shield-icon.svg";
import CustomizeIconImage from "../../images/customize-icon.svg";
import FastIconImage from "../../images/fast-icon.svg";
import ReliableIconImage from "../../images/reliable-icon.svg";
import SimpleIconImage from "../../images/simple-icon.svg";

import img from '../../images/backgrounds/procure-to-pay.jpg'
import imgCentral from '../../images/request/why_esker_laptop_mobile_2.png'

const Container = tw.div`relative -mt-6`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center mx-auto py-20 md:py-24`}
`;
const Heading = tw(SectionHeading)`w-full font-bold max-w-screen-sm p-5 leading-tight`;
const Subheading = tw(SubheadingBase)`w-full font-bold text-2xl text-center mb-4`;
const Description = tw(SectionDescription)`w-full text-center max-w-screen-lg mt-0`;

const VerticalSpacer = tw.div`mt-10 w-full`

const Column = styled.div`
  ${tw`sm:w-2/3 flex flex-row px-2 pt-4`}
`;
// md:w-1/3 lg:w-1/3 flex flex-row
const Card = styled.div`
  ${tw`flex flex-row justify-center sm:text-left h-full`}
  .imageContainer {
    ${tw`flex text-center rounded-full `}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw``}
  }

  .title {
    ${tw`font-bold tracking-wide text-lg leading-none mb-8`}
  }

  .subtitle {
    ${tw`font-bold text-red-700 tracking-wide text-lg leading-none mb-8`}
  }

  .description {
    ${tw`mt-2 text-secondary-300`}
  }

  .list {
    ${tw`text-secondary-200`}
  }
`;

// const imageContainer = tw.img`mt-10`

export default ({
    heading = "",
    subheading = "", 
    description = "",
    cards = null,
    list = []
}) => {


  //reformular las columnas con columnas separadas y no a partir de un array asi puedo incluir la descripcion sin problemas
  return (
    <Container>
      <ThreeColumnContainer>
        <Heading>{heading}</Heading>
        {subheading && <Subheading>{subheading}</Subheading>}
        {description && <Description>{description}</Description>}
        <VerticalSpacer />
        <Column>
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="textContainer">
                <span className="title">{card.title}</span>
                <span className="subtitle">{card.span}</span>
                <p tw="flex flex-col" className="description">
                  {card.description}
                </p>
                <br/>
                  <ul tw="mt-2 text-sm">
                  {list.map((item, index) => (
                    <li tw="list-decimal text-gray-700 pb-2" key={index}>
                      <span>{item}</span>
                    </li>
                  ))}
                  </ul>
              </span>
            </Card>
          </Column>
        ))}
        </Column>
      </ThreeColumnContainer>
    </Container>
  );
};
// const cardsDefault = [
//   {
//     title: "FRONT-END ",
//     span: "Development",
//     description: "We ensure that our front-end development services integrate well with web services and other external technologies. Our front-end trained designers are to understand business logic and customer objectives, to deliver with unique and top-notch solutions."
//   },
//   { 
//     title: "MIDDLEWARE ",
//     span: "Development",
//     description: "We ensure that our front-end development services integrate well with web services and other external technologies. Our front-end trained designers are to understand business logic and customer objectives, to deliver with unique and top-notch solutions."
//   },
//   { 
//     title: "BACKEND ",
//     span: "Development",
//     description: "Request has strong experience in backend development of following applications:",
//     item_1: "• Cloud Migration",
//     item_2: "• Backend Modernization", 
//     item_3: "• API Integration", 
//     item_4: "• User Management", 
//     item_5: "• Messaging Services", 
//     item_6: "• Data Synchronization", 
//     item_7: "• Data Storage", 
//     item_8: "• Mobility", 
//     item_9: "• Customer Relationship Management", 
//     item_10: "• Platform Integration", 
//   }
// ];


// if (!cards) cards = cardsDefault;

// return (
//   <Container>
//     <ThreeColumnContainer>
//       <Heading>{heading}</Heading>
//       {subheading && <Subheading>{subheading}</Subheading>}
//       {description && <Description>{description}</Description>}
//       <VerticalSpacer />
//       <Column>
//       {cards.map((card, i) => (
//         <Column key={i}>
//           <Card>
//             <span className="textContainer">
//               <span className="title">{card.title}</span>
//               <span className="subtitle">{card.span}</span>
//               <p tw="flex flex-col" className="description">
//                 {card.description}
//                 <br/>
//                 <ul tw="mt-2 text-sm">
//                   <li className="list">{card.item_1}</li>
//                   <li className="list">{card.item_2}</li>
//                   <li className="list">{card.item_3}</li>
//                   <li className="list">{card.item_4}</li>
//                   <li className="list">{card.item_5}</li>
//                 </ul>
//                 <ul tw="mt-2 text-sm">
//                 <li className="list">{card.item_6}</li>
//                   <li className="list">{card.item_7}</li>
//                   <li className="list">{card.item_8}</li>
//                   <li className="list">{card.item_9}</li>
//                   <li className="list">{card.item_10}</li>
//                 </ul>
                
//               </p>
//             </span>
//           </Card>
//         </Column>
//       ))}
//       </Column>
//     </ThreeColumnContainer>
//   </Container>
// );
// };