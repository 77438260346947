import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center mx-auto `}
`;
const Title = tw(SectionHeading)`w-full text-left font-bold text-4xl text-left mb-4 uppercase max-w-screen-lg`;
const Heading = tw(SectionHeading)`w-full text-left font-bold text-2xl text-left mb-4 uppercase max-w-screen-lg p-0`;
const Subheading = tw(SubheadingBase)`w-full text-left font-bold text-lg text-left mb-4 normal-case max-w-screen-lg text-gray-800`;
const Description = tw(SectionDescription)`w-full text-left max-w-screen-lg mt-0`;
// const Heading = tw(SectionHeading)`w-full max-w-screen-md text-center`;
// const HeadingCarousel = tw(SectionHeading)`w-full max-w-screen-md leading-tight p-2 mx-auto uppercase text-3xl sm:text-3xl text-center`;
// const Subheading = tw(SubheadingBase)`w-full font-bold text-2xl text-center mb-4 uppercase`;

export default ({
    title = "",
    heading = "",
    subheading = "", 
    description = "",
}) => {


  return (
    <Container>
      <ThreeColumnContainer>
      {title && <Title>{title}</Title>}
      <br/>
      {heading && <Heading>{heading}</Heading>}
      {subheading && <Subheading>{subheading}</Subheading>}
      {description.map((parrafo, index) => (
        <>
          <Description key={index}>{ parrafo }</Description>
          <br/>
        </>
      ))}
      </ThreeColumnContainer>
    </Container>
  );
};
