import React from "react";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line

import AliaxisCase from "../images/real_case_study/Aliaxis.jpg"
import DomingoAlonsoCase from "../images/real_case_study/DomingoAlonso.jpg"
import EmasalCase from "../images/real_case_study/Emasal.jpg"
import SiemensHealthineersCase from "../images/real_case_study/SiemensHealthineers.jpg"

const rutaServidor = "/requestsa"

export var dataNewsPage = {
    header: {
        heading: "News",
        subheading: "",
        headerImg: "",
    },
    news: {
        heading: "? ",
        headingSpan: "?",
        cardLinkText: "Read More",
        textOnLeft: false,
        cards: [
            {
              imageSrc: SiemensHealthineersCase,
              company: "Tripters",
              type: "tripters",
              title: "tripters",
              buttonLink: "https://tripters.com/"
            },
            {
              imageSrc: "",
              company: "",
              type: "",
              title: "",
            },
            {
              imageSrc: "",
              company: "",
              type: "",
              title: "",
            },
            {
              imageSrc: "",
              company: "",
              type: "",
              title: "",
            },
            
        ]
    }
};
export default dataNewsPage

// buttonLink: `${rutaServidor + '/siemens-healthineers-case'}`