import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line

//Componentes
import Home from 'components/hero/PagesComponent'
import Objetivos from "components/cards/ThreeColImgCenter";
import Footer from "components/footers/RequestFooter";

import Header, { NavLink, NavLinks, PrimaryLink as PrimaryLinkBase, LogoLink, NavToggle, DesktopNavLinks } from "../components/headers/light";

import img from '../images/backgrounds/aboutus.jpg'


const PrimaryLink = tw(PrimaryLinkBase)`rounded-full`

const StyledHeader = styled(Header)`
  ${tw`max-w-none w-full bg-red-800 fixed h-24 w-screen z-20 p-2 shadow-xl text-gray-400`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw` hover:border-gray-300 hover:text-white`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;

const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 `}`;


export default () => {

  return (
    <AnimationRevealPage disabled>
      <Container>
        <StyledHeader />
        <Home
          heading = "About Us"
          subheading = ""
          imgSrc={img}
        />
        {/* <Objetivos
          heading="Our Mision"
          subheading="lorem ipsum donor"
          description="Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud,Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud.Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud."
          cards={[
            {
              title: "Order Management",
              description: "We strictly only deal with vendors that provide top notch security."
            },
            { 
              title: "Credit Management",
              description: "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud."
            },
            { 
              title: "Invoice delivery",
              description: "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud."
            }
          ]}
          cardsR={[
            {
              title: "Order Management",
              description: "We strictly only deal with vendors that provide top notch security."
            },
            { 
              title: "Credit Management",
              description: "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud."
            },
            { 
              title: "Invoice delivery",
              description: "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud."
            }
          ]}
          imgSrc=""
        /> */}
        <Footer />
      </Container>
    </AnimationRevealPage>
  );
};
